<template v-if="this.hasView === true">
  <div class="childpool-wrap">
    <CCard>
      <CCardBody>
        <CRow class="mb-3">
          <CCol lg="12" class="text-right">
            <CButton name="new" class="btn-add-child" @click="handleNew" v-if="this.hasAdd === true">
              <span>
                + {{$t("CHILDPOOL_LIST_PAGE_BUTTON_NEW_POOL")}}
              </span>
            </CButton>
          </CCol>
        </CRow>
        <CRow alignHorizontal="center">
          <CCol lg="12">
            <Tables
              :items="partners"
              :fields="fields"
              :handleEdit="handleEdit"
              :handleConfig="handleConfig"
              hover
              striped
              bordered
              :offset="offset"
            ></Tables>
            <div>
              <CPagination
                v-if="pages>1"
                :activePage.sync="activePage"
                :pages="pages"
                align="end"
                @update:activePage="pushPage"
              />
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import Tables from "@/components/ChildPool/Tables";
import { cilMedicalCross } from "@coreui/icons";
import endpoints from "@/constants/endpoints";
import { mapState, mapActions } from "vuex";
import responseHelper from '@/utils/response-helper';
export default {
  name: "ListChildPool",
  data() {
    return {
      cilMedicalCross,
      fields: [
        "#",
        {
          key: "partner_type",
          label: this.$t('CHILDPOOL_LIST_PAGE_LABEL_TYPE')
        },
        {
            key: "id",
            label: this.$t('CHILDPOOL_LIST_PAGE_LABEL_PARTNER_ID')
        },
        {
          key: "name",
          label: this.$t('CHILDPOOL_LIST_PAGE_LABEL_PARTNER_NAME')
        },
        {
          key: "action",
          label: this.$t('CHILDPOOL_LIST_PAGE_LABEL_ACTION'),
          noSorting: true,
          noFilter: true
        }
      ],
      partners: [],
      total: 0,
      limit: 5,
      activePage: 1,
      pages: 0,
      openModal: false,
      hasView: false,
      hasAdd: false
    };
  },
  components: {
    Tables
  },
  computed: {
    ...mapState({
       userPermission: state => state.auth.userPermission,
    }),
    offset() {
      return this.limit * (this.activePage - 1)
    }
  },
  async mounted() {
    let data = await this.loadChildPool();
    if(!data) return;
    this.partners = data.items.slice();
    let totalItems = parseInt(data.total);
    this.pages = Math.ceil(totalItems / this.limit);
    await this.checkPermission();
  },
  methods: {
    ...mapActions(["getSelectedPartner"]),
    async loadChildPool() {
      try {
        const params = {
           limit: this.limit, 
           offset: this.offset
           };
        let result = await this.$http.get(endpoints.getPartners, {
          params: params
        });
        return result.data;
      } catch (err) {
        console.log(err);
        return null;
      }
    },
    handleNew() {
      this.$router.push("childpool/create");
    },
    handleEdit(item) {
      let partner = this.partners.filter(partner => partner.id === item)[0];
      this.getSelectedPartner(partner);
      this.$router.push(`childpool/edit/${item}`);
    },
    /**
     * Handle after use click on config button, save selected partner into the store and push route
     * @param {number} item
     * @return 
     */
    handleConfig(item) {
      // AnhND code start
      try {
        // Get selected partner obj
        let partner = this.partners.find(partner => partner.id === item);
        // Dispatch action save to the store
        this.getSelectedPartner(partner);
        this.$router.push(`childpool/detail/${item}`);
        return true;
      }
      catch (err) {
        this.$notify({
          group: "notifications",
          type: "error",
          title: this.$t("CHILDPOOL_LIST_PAGE_TITLE_NOTIFY_ERROR"),
          text: responseHelper.getErrorMessage(err),
        });
        return false;
      }          
      // AnhND code end
    },

    async pushPage() {
      let data = await this.loadChildPool();
      if(!data) return;

      this.partners = data.items.slice();
    },
    checkPermission(){
        if(this.userPermission.length > 0){
            this.userPermission.forEach(items =>{
                if(items.name === 'VIEW_LIST_PARTNER'){
                    this.hasView = true
                }
                if(items.name === 'CREATE_PARTNER'){
                    this.hasAdd = true
                }
            })
        }
    }
  }
};
</script>

<style lang="scss">
.childpool-wrap{
  .btn-add-child{
    background: #3B4B7E;
    border: none;
    border-radius: 32px;
    height: 32px;
    padding: 0 30px;
    span{
      color: #FFFFFF;
      // font-family: "SF UI Display";
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.5px;
      text-align: center;
    }
  }
}
</style>